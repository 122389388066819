var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "consu-container" },
    [
      _c("v-consultation-header"),
      _c("div", { staticClass: "consultation" }, [
        _c(
          "div",
          { staticClass: "consultation-content" },
          [
            _c("consultation", {
              ref: "consultation",
              on: { notify: _vm.getChecked },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "btn flex f-jc-c f-ai-c",
            class: { disabled: !_vm.canNext },
          },
          [
            _c("div", { on: { click: _vm.nextStep } }, [
              _c("span", [_vm._v("下一步")]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }